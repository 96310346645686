import { IAuthTokens } from '../../../../api/auth/interfaces';

/* --- STATE --- */
export interface AuthState {
  isLogged?: boolean;
  tokens: ITokens;
  error?: string;
}

export interface ITokens extends IAuthTokens {
  expires_at: number;
  refresh_expires_at: number;
}

/**
 * AuthActionTypes
 */

export enum AuthActionTypes {
  GetAuthTokensByCode = 'getAuthTokensByCode',
  LoadLocalAuthTokens = 'loadLocalAuthTokens',
  RefreshTokens = 'refreshTokens',
  RemoveLocalAuthTokens = 'removeLocalAuthTokens',
  Logout = 'logout',
}
