import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons';
import { Api } from 'api';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { cn } from 'utils/shadcn';
import { z } from 'zod';
import { ESortOrder } from '../../../../api/base-api.interface';
import {
  ETopicKeySort,
  INameTopic,
  IPaginativeQueryTopic,
  ITopic,
} from '../../../../api/topic';
import { Filter, FilterSchema } from '../../../components/Filter';
import { Badge } from '../../../components/ui/badge';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from '../../../components/ui/pagination';
import { Skeleton } from '../../../components/ui/skeleton';
import { TopicSortBy } from '../../../constants';

interface SelectTopicProps {
  values: INameTopic[];
  onChange: Dispatch<SetStateAction<INameTopic[]>>;
}
export function SelectTopic(props: SelectTopicProps) {
  const { values, onChange } = props;

  const [open, setOpen] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<IPaginativeQueryTopic>({
    page: 1,
    pageSize: 12,
  });
  const [total, setTotal] = React.useState<number>(0);
  const [dataTopics, setDataTopics] = React.useState<ITopic[]>([]);
  const [loadingTopics, setLoadingTopics] = React.useState<boolean>(false);

  const countPage = React.useCallback(() => {
    return total % filter.pageSize
      ? Math.floor(total / filter.pageSize) + 1
      : total / filter.pageSize;
  }, [total, filter]);

  const handleChange = (offset: number) => {
    const tempPage = filter.page + offset;
    if (tempPage > 0 && tempPage < countPage()) {
      setFilter({ ...filter, page: tempPage });
    }
  };

  const addMoreTopic = (topic: ITopic) => {
    let newData = values ? values.map(value => value) : [];
    if (newData.some(data => data.id.includes(topic.id))) {
      newData = newData.filter(data => data.id !== topic.id);
    } else newData.push({ id: topic.id, name: topic.name });
    onChange(newData);
  };

  const handleSearch = React.useCallback(
    (values: z.infer<typeof FilterSchema>) => {
      setFilter({
        ...filter,
        page: 1,
        search: values.search,
        sortBy: values?.sortBy?.[0] as ETopicKeySort,
        sortOrder: values?.sortOrder?.[0] as ESortOrder,
      });
    },
    [],
  );

  useEffect(() => {
    (async () => {
      setLoadingTopics(true);
      const res = await Api.topic.getListTopic(filter);
      setDataTopics([...res.items]);
      setTotal(res.total);
      setLoadingTopics(false);
    })();
  }, [filter]);

  return (
    <>
      <div
        className={cn(
          'border rounded-md min-h-[2.5rem] overflow-y-auto p-2 flex gap-2 flex-wrap items-center cursor-pointer focus:border-black',
        )}
        tabIndex={0}
        onClick={() => setOpen(true)}
      >
        {values &&
          values.map(value => (
            <Badge key={value.id} variant="secondary">
              {value.name}
            </Badge>
          ))}
      </div>
      {open && (
        <div className="border rounded-md p-4 space-y-4">
          <Filter sortBy={TopicSortBy} handleSubmit={handleSearch} />
          {loadingTopics ? (
            <Skeleton className="w-full h-60" />
          ) : (
            <section>
              <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4">
                {dataTopics.map(item => (
                  <div
                    key={item.id}
                    className="w-full flex space-x-4 p-2 items-center border rounded-md hover:bg-gray-100 cursor-pointer"
                    onClick={() => addMoreTopic(item)}
                  >
                    <div className="aspect-square w-16 rounded-md overflow-hidden border">
                      <img
                        src={item.thumbnail.url}
                        alt="thumbnail-preview"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="font-bold flex-1 max-w-[50%] line-clamp-2">
                      {item.name}
                    </div>
                    {values?.some(value => value.name.includes(item.name)) && (
                      <CheckIcon className="w-5 h-5" />
                    )}
                  </div>
                ))}
              </div>
              <Pagination className="mt-2">
                <PaginationContent>
                  <PaginationItem>
                    <PaginationLink
                      className="cursor-pointer"
                      onClick={() => handleChange(-1)}
                    >
                      <ChevronLeftIcon />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      className="cursor-pointer"
                      onClick={() => handleChange(1)}
                    >
                      <ChevronRightIcon />
                    </PaginationLink>
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </section>
          )}
        </div>
      )}
    </>
  );
}
