/**
 *
 * Asynchronously loads the component for VideoPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AudioPage = lazyLoad(
  () => import('./index'),
  module => module.AudioPage,
);
