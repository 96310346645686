import { Badge } from 'app/components/ui/badge';
import * as React from 'react';
import { INameTopic } from '../../../../api/topic';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../components/ui/popover';

interface ListTopicProps {
  topics: INameTopic[];
}

export function ListTopic(props: ListTopicProps) {
  const { topics } = props;

  const [open, setOpen] = React.useState<boolean>(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          className="focus-visible:outline-0"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-wrap w-96 h-6 overflow-hidden">
            {topics.map(item => (
              <Badge key={item.id} variant={'secondary'}>
                {item.name}
              </Badge>
            ))}
          </div>
        </PopoverTrigger>
        <PopoverContent className="min-w-96 max-w-96">
          <div className="flex flex-wrap -mt-2">
            {topics.map(item => (
              <Badge key={item.id} variant={'secondary'} className="mt-2">
                {item.name}
              </Badge>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
