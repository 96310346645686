import { ReloadIcon } from '@radix-ui/react-icons';
import { Api } from 'api';
import { isAxiosError } from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { debug, parseError } from 'utils/functions';
import { IResourceResponse } from '../../../../api/resource';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';
import { useToast } from '../../../components/ui/use-toast';
import { useAdminResourceSlice } from './slice';
import { AdminResourceActionTypes } from './slice/types';

interface DeleteResourceDialogProps {
  resource: IResourceResponse;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DeleteResourceDialog(props: DeleteResourceDialogProps) {
  const { resource, open, setOpen } = props;

  const [isSubmiting, setIsSubmiting] = React.useState<boolean>(false);
  // Redux
  const dispatch = useDispatch();
  const { actions } = useAdminResourceSlice();
  //Hook
  const { toast } = useToast();
  // Function
  const handleSubmit = async () => {
    try {
      setIsSubmiting(true);
      await Api.resource.deleteResource(resource.id);
      dispatch({ type: AdminResourceActionTypes.GetListResouce });
      toast({
        variant: 'success',
        title: 'Xóa nội dung thành công',
      });
      setOpen(false);
    } catch (error) {
      debug(error);
      if (isAxiosError(error)) {
        dispatch(actions.setError(parseError(error).message));
        toast({
          variant: 'destructive',
          title: parseError(error).message,
        });
      }
    } finally {
      setIsSubmiting(false);
    }
  };
  return (
    <Dialog open={open} onOpenChange={open => !open && setOpen(false)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Xóa nội dung {resource.name}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Bạn có chắc chắn muốn xóa nội dung này? Việc xóa nội dung này là không
          thể hoàn tác.
        </DialogDescription>
        <DialogFooter>
          <Button
            disabled={isSubmiting}
            onClick={handleSubmit}
            variant={'destructive'}
            className="min-w-32"
          >
            {isSubmiting ? (
              <ReloadIcon className="mr-2 h-5 w-5 animate-spin" />
            ) : (
              'Xóa'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
