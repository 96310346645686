/**
 *
 * LoginPage
 *
 */
import { CrossCircledIcon, EnterIcon, ReloadIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { Button } from '../../components/ui/button';
import { Toaster } from '../../components/ui/toaster';
import { useToast } from '../../components/ui/use-toast';
import { selectAuth } from '../AuthPage/slice/selectors';
import { useLoginSlice } from './slice';
import { LoginActionTypes } from './slice/saga';
import { selectLogin } from './slice/selectors';

export function LoginPage() {
  // TODO
  // Check auth state if user was logged.
  // Else redirect to /auth with redirectUrl

  const dispatch = useDispatch();
  useLoginSlice();
  const { url, logoutUrl, error } = useSelector(selectLogin);
  const { error: errorAuth } = useSelector(selectAuth);

  const { toast } = useToast();

  React.useEffect(() => {
    dispatch({ type: LoginActionTypes.GetSsoUrl });
    dispatch({ type: LoginActionTypes.GetLogoutUrl });
  }, []);

  React.useEffect(() => {
    if (errorAuth)
      toast({
        variant: 'destructive',
        title: errorAuth,
      });
  }, [errorAuth]);

  return (
    <>
      <Helmet title="Đăng nhập"></Helmet>
      <main>
        <div className="h-dvh p-4 flex items-center">
          <div className="hidden lg:block lg:w-1/2 xl:w-3/5 h-full rounded-xl overflow-hidden relative">
            <div className="absolute top-0 left-0 w-full h-full z-10 bg-primary/40"></div>
            <img
              src={require('../../assets/banner.jpg')}
              alt="banner"
              className="w-full h-full object-cover object-center z-0"
            />
          </div>
          <div className="w-full lg:pl-4 lg:w-1/2 xl:w-2/5 flex flex-col">
            <div className="mx-auto max-w-72 max-h-72">
              <img
                src={require('../../assets/wetube.png')}
                alt="wetube-logo"
                className="w-full h-full object-center object-contain"
              />
            </div>
            <h1 className="font-bold text-3xl text-center text-primary mt-4">
              Xin chào
            </h1>
            <p className="text-center mt-2">
              Hãy đăng nhập để sử dụng các dịch vụ của chúng tôi.
            </p>
            <div className="mt-8 mx-auto space-y-2">
              {error ? (
                <Alert variant={'destructive'} className="mb-4">
                  <CrossCircledIcon className="size-4" />
                  <AlertTitle className="font-semibold">Lỗi!</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              ) : (
                <a href={url ?? '#'} className="block mx-auto">
                  <Button className="w-full" size={'xl'} disabled={!url}>
                    {!url ? (
                      <>
                        <ReloadIcon className="mr-2 animate-spin" />
                        Đang tải...
                      </>
                    ) : (
                      <>
                        <EnterIcon className="mr-2" />
                        Đi đến trang đăng nhập
                      </>
                    )}
                  </Button>
                </a>
              )}
              {errorAuth && (
                <a
                  href={logoutUrl ?? '#'}
                  className="block mx-auto"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="w-full" variant={'link'}>
                    Chuyển đổi tài khoản
                  </Button>
                </a>
              )}
            </div>
          </div>
        </div>
        <Toaster />
      </main>
    </>
  );
}
