import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { ESortOrder } from '../../../../api/base-api.interface';
import { EResourceKeySort, EResourceType } from '../../../../api/resource';
import { PageHeader } from '../../../components/BaseAdminPage/PageHeader';
import { Filter, FilterSchema } from '../../../components/Filter';
import { Skeleton } from '../../../components/ui/skeleton';
import {
  AppPageMetaData,
  AppRoutes,
  ResourceSortBy,
  TypeFilter,
} from '../../../constants';
import { AddResourceDialog } from './AddResourceDialog';
import { DataTable, ResourceTableColumn } from './ResourceTable';
import { useAdminResourceSlice } from './slice';
import { selectAdminResource } from './slice/selectors';
import { AdminResourceActionTypes } from './slice/types';

interface Props {}

export function AdminManageResources(props: Props) {
  const dispatch = useDispatch();
  const { actions } = useAdminResourceSlice();
  const { loading, filter, resources } = useSelector(selectAdminResource);

  const tableData = React.useMemo(
    () => (loading ? Array(5).fill({}) : resources.items),
    [loading, resources],
  );
  const tableColumns = React.useMemo(
    () =>
      loading
        ? ResourceTableColumn.map(column => ({
            ...column,
            cell: () => <Skeleton className="h-4 w-40" />,
          }))
        : ResourceTableColumn,
    [loading],
  );

  const handleSearch = React.useCallback(
    (values: z.infer<typeof FilterSchema>) => {
      dispatch(
        actions.setFilter({
          ...filter,
          page: 1,
          search: values.search,
          sortBy: values?.sortBy?.[0] as EResourceKeySort,
          sortOrder: values?.sortOrder?.[0] as ESortOrder,
          type: values.type?.[0] as EResourceType,
        }),
      );
    },
    [],
  );

  React.useEffect(() => {
    dispatch({ type: AdminResourceActionTypes.GetListResouce });
  }, [filter]);
  return (
    <>
      <PageHeader metadata={AppPageMetaData[AppRoutes.AdminManageResources]} />
      <section className="flex gap-1 justify-between">
        <Filter
          handleSubmit={handleSearch}
          sortBy={ResourceSortBy}
          type={TypeFilter}
          className="min-w-[440px]"
        />
        <AddResourceDialog />
      </section>
      <section>
        <DataTable
          columns={tableColumns}
          data={tableData}
          total={resources.total}
        />
      </section>
    </>
  );
}
