import axios from 'axios';
import urlJoin from 'url-join';
import { BaseApi } from '../base-api';
import { ICreateSessionData, IMStorageApi, ISession } from './interfaces';

export class MStorageApi extends BaseApi implements IMStorageApi {
  constructor() {
    super('mstorage');
  }

  async createSession(data: ICreateSessionData): Promise<ISession> {
    const response = await this.axiosClient.post<ISession>(
      urlJoin(this.version, this.path, 'session'),
      data,
    );
    return response.data;
  }

  async uploadFile(url: string, file: FormData): Promise<void> {
    await axios.post(url, file);
  }
}
