import { IPaginativeResponse } from '../../../../../api/base-api.interface';
import {
  IPaginativeQueryResource,
  IResourceResponse,
} from '../../../../../api/resource';

/* --- STATE --- */
export interface AdminResourceState {
  loading: boolean;
  filter: IPaginativeQueryResource;
  resources: IPaginativeResponse & { items: IResourceResponse[] };
  error?: string;
}

/**
 * AdminResourceActionTypes
 */

export enum AdminResourceActionTypes {
  GetListResouce = 'getListResourceAdmin',
}
