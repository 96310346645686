import { Api } from 'api';
import { isAxiosError } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { debug, parseError } from 'utils/functions';
import { adminResourceActions } from '.';
import { selectAdminResource } from './selectors';
import { AdminResourceActionTypes, AdminResourceState } from './types';

function* getListResource() {
  try {
    yield put(adminResourceActions.setLoading(true));
    const { filter }: AdminResourceState = yield select(selectAdminResource);
    const resources = yield call(
      Api.resource.getListResource.bind(Api.resource, filter),
    );
    yield put(adminResourceActions.setResources(resources));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      yield put(adminResourceActions.setError(parseError(error).message));
    }
  } finally {
    yield put(adminResourceActions.setLoading(false));
  }
}

export function* adminResourceSaga() {
  yield takeLatest(AdminResourceActionTypes.GetListResouce, getListResource);
}
