import { IPaginativeResponse } from 'api/base-api.interface';
import urlJoin from 'url-join';
import { BaseApi } from '../base-api';
import {
  IPaginativeQueryTopic,
  ITopic,
  ITopicApi,
  ITopicData,
} from './interfaces';

export class TopicApi extends BaseApi implements ITopicApi {
  constructor() {
    super('topics');
  }

  async createTopic(data: ITopicData): Promise<ITopic> {
    const response = await this.axiosClient.post<ITopic>(
      urlJoin(this.version, this.path),
      data,
    );
    return response.data;
  }
  async updateTopic(id: string, data: ITopicData): Promise<void> {
    await this.axiosClient.patch<void>(
      urlJoin(this.version, this.path, id),
      data,
    );
  }
  async deleteTopic(id: string): Promise<void> {
    await this.axiosClient.delete<void>(urlJoin(this.version, this.path, id));
  }
  async getListTopic(
    query?: IPaginativeQueryTopic | undefined,
  ): Promise<IPaginativeResponse & { items: ITopic[] }> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path),
      {
        params: query,
      },
    );
    return response.data;
  }
}
