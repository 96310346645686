import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  ESortOrder,
  IPaginativeResponse,
} from '../../../../../api/base-api.interface';
import {
  EResourceKeySort,
  IPaginativeQueryResource,
  IResourceResponse,
} from '../../../../../api/resource';
import { adminResourceSaga } from './saga';
import { AdminResourceState } from './types';

export const initialState: AdminResourceState = {
  loading: true,
  filter: {
    page: 1,
    pageSize: 10,
    sortBy: EResourceKeySort.CreatedAt,
    sortOrder: ESortOrder.DESC,
  },
  resources: {
    total: 0,
    items: [],
  },
};

const slice = createSlice({
  name: 'adminResource',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setFilter(state, action: PayloadAction<IPaginativeQueryResource>) {
      state.filter = { ...state.filter, ...action.payload };
    },
    setResources(
      state,
      action: PayloadAction<
        IPaginativeResponse & { items: IResourceResponse[] }
      >,
    ) {
      state.resources = { ...state.resources, ...action.payload };
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const { actions: adminResourceActions } = slice;

export const useAdminResourceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: adminResourceSaga });
  return { actions: slice.actions };
};
