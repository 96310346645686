import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';
import { IResourceResponse } from '../../../../api/resource';
import { Button } from '../../../components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import { DeleteResourceDialog } from './DeleteResourceDialog';
import { EditResouceDialog } from './EditResourceDialog';

interface DropdownMenuTableProps {
  resource: IResourceResponse;
}
export function DropdownMenuTable(props: DropdownMenuTableProps) {
  const { resource } = props;
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => setIsEditDialogOpen(true)}>
            Sửa
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => setIsDeleteDialogOpen(true)}>
            Xóa
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {isEditDialogOpen && (
        <EditResouceDialog
          resource={resource}
          open={isEditDialogOpen}
          setOpen={setIsEditDialogOpen}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteResourceDialog
          resource={resource}
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
        />
      )}
    </>
  );
}
