/**
 *
 * AuthPage
 *
 */
import { AppRoutes } from 'app/constants';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageLoader } from '../../components/PageLoader';
import { useProfileSlice } from '../Web/ProfilePage/slice';
import { ProfileActionTypes } from '../Web/ProfilePage/slice/types';
import { useAuthSlice } from './slice';
import { selectAuth } from './slice/selectors';
import { AuthActionTypes } from './slice/types';

interface Props {}

export function AuthPage(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();
  useAuthSlice();
  useProfileSlice();
  const { isLogged, error } = useSelector(selectAuth);

  React.useEffect(() => {
    // if url has code, call api to obtain tokens
    // else, validate local tokens
    const code = query.get('code');
    if (code) {
      // get authenticated user information and save to state
      dispatch({ type: AuthActionTypes.GetAuthTokensByCode, code });
    } else {
      // Validate local tokens
      dispatch({ type: AuthActionTypes.LoadLocalAuthTokens });
    }

    // If url has `redirectUrl`, navigate to the page
    // else, navigate to homepage
  }, []);

  React.useEffect(() => {
    if (isLogged) {
      dispatch({ type: ProfileActionTypes.GetProfile });
      navigate(
        (location.state as { pathname?: string } | null)?.pathname ??
          AppRoutes.Home,
        { replace: true },
      );
    } else if (isLogged === false || error)
      navigate(AppRoutes.Login, {
        replace: true,
      });
  }, [isLogged, error]);

  return (
    <main className="container h-dvh flex items-center justify-center">
      <PageLoader className="size-24" />
    </main>
  );
}
