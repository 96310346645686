import { IPaginativeQuery, IPaginativeResponse } from '../base-api.interface';
import { IThumbnail } from '../mstorage';

export interface ITopicApi {
  createTopic(data: ITopicData): Promise<ITopic>;
  getListTopic(
    query?: IPaginativeQueryTopic,
  ): Promise<IPaginativeResponse & { items: ITopic[] }>;
  updateTopic(id: string, data: ITopicData): Promise<void>;
  deleteTopic(id: string): Promise<void>;
}

export interface ITopicData {
  name: string;
  description: string;
  thumbnail?: string;
}

export interface ITopic {
  id: string;
  name: string;
  description: string;
  slug: string;
  thumbnail: IThumbnail;
}

export interface IPaginativeQueryTopic extends IPaginativeQuery {
  sortBy?: ETopicKeySort;
  topics?: string[];
}

export interface INameTopic {
  id: string;
  name: string;
}

export enum ETopicKeySort {
  Name = 'name',
  Slug = 'slug',
  CreatedAt = 'createdAt',
}
