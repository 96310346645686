import { IUserWithRole } from '../../../../../api/user';

/* --- STATE --- */
export interface ProfileState {
  loading: boolean;
  user?: IUserWithRole;
  error?: string;
}

/**
 * ProfileActionTypes
 */

export enum ProfileActionTypes {
  GetProfile = 'getProfile',
}
