import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { AuthState, ITokens } from './types';

export const initialState: AuthState = {
  tokens: {
    access_token: '',
    expires_in: 0,
    refresh_expires_in: 0,
    refresh_token: '',
    expires_at: 0,
    refresh_expires_at: 0,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogged(state, action: PayloadAction<boolean>) {
      state.isLogged = action.payload;
    },
    setTokens(state, action: PayloadAction<ITokens>) {
      state.tokens = action.payload;
    },
    setError: (state, action: { payload: string }) => {
      state.error = action.payload;
    },
  },
});

export const { actions: authActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
