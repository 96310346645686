import { Api } from 'api';
import { isAxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginActions } from '.';
import { debug, parseError } from '../../../../utils/functions';

function* getSsoUrl() {
  try {
    const url = yield call(Api.auth.getSsoUrl.bind(Api.auth));
    yield put(loginActions.setUrl(url));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      yield put(loginActions.setError(parseError(error).message));
    }
  }
}

function* getLogoutUrl() {
  try {
    const url = yield call(Api.auth.getChangePasswordUrl.bind(Api.auth));
    yield put(loginActions.setLogoutUrl(url));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      yield put(loginActions.setError(parseError(error).message));
    }
  }
}

// Action Types
export enum LoginActionTypes {
  GetSsoUrl = 'getSsoUrl',
  GetLogoutUrl = 'getLogoutUrl',
}

export function* loginSaga() {
  yield takeLatest(LoginActionTypes.GetSsoUrl, getSsoUrl);
  yield takeLatest(LoginActionTypes.GetLogoutUrl, getLogoutUrl);
}
