export enum AppRoutes {
  Login = '/login',
  Auth = '/auth',
  Home = '/',
  Topics = '/topics',
  ResourceTopic = '/topics/:slug',
  Videos = '/videos',
  Audios = '/audios',
  Profile = 'profile',

  AdminDashboard = '/admin',
  AdminManageTopics = '/admin/manage-topics',
  AdminManageResources = '/admin/manage-resources',
  AdminManageUsers = '/admin/manage-users',
}

export type AppPageMetaDataItem = {
  title: string;
  description?: string;
};
export const AppPageMetaData: Record<AppRoutes, AppPageMetaDataItem> = {
  [AppRoutes.Login]: { title: 'Đăng nhập' },
  [AppRoutes.Auth]: { title: 'Xác thực' },
  [AppRoutes.Home]: { title: 'Trang chủ' },
  [AppRoutes.Topics]: { title: 'Chủ đề' },
  [AppRoutes.ResourceTopic]: { title: 'Chủ đề' },
  [AppRoutes.Videos]: { title: 'Video' },
  [AppRoutes.Audios]: { title: 'Bản thu âm' },
  [AppRoutes.Profile]: { title: 'Thông tin cá nhân' },

  [AppRoutes.AdminDashboard]: {
    title: 'Tổng quan',
    description:
      'Các con số nổi bật về hoạt động kinh doanh của bạn sẽ được hiển thị trực quan hóa dưới nhiều dạng biểu đồ, từ đó giúp bạn nắm bắt và đưa ra chiến lược kinh doanh phù hợp.',
  },
  [AppRoutes.AdminManageTopics]: { title: 'Chủ đề' },
  [AppRoutes.AdminManageResources]: { title: 'Thư viện nội dung' },
  [AppRoutes.AdminManageUsers]: { title: 'Thành viên' },
};
