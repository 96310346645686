/**
 *
 * Asynchronously loads the component for TopicPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const TopicPage = lazyLoad(
  () => import('./index'),
  module => module.TopicPage,
);
