/**
 *
 * Asynchronously loads the component for VideoPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const VideoPage = lazyLoad(
  () => import('./index'),
  module => module.VideoPage,
);
