import { ITopic } from 'api/topic';
import { isAxiosError } from 'axios';
import { ITokens } from '../app/pages/AuthPage/slice/types';

export function debug(...args: any[]) {
  if (process.env.REACT_APP_DEBUG === 'true')
    console.log(`[DEBUG - ${new Date().toLocaleString()}]`, ...args);
}

export async function wait(seconds: number) {
  return new Promise(res => setTimeout(res, seconds * 1000));
}

export function parseError(error: any): {
  isAxiosErr: boolean;
  message: string;
  statusText: string;
} {
  const isAxiosErr = isAxiosError(error);
  return {
    isAxiosErr,
    message:
      (isAxiosErr ? error.response?.data.message : error.message) ??
      'Unknown error.',
    statusText: (isAxiosErr ? error.code : error.name) ?? 'Error',
  };
}

export function setTokensToLocal(authTokens: ITokens) {
  localStorage.setItem('auth_tokens', JSON.stringify(authTokens));
}

export function getTokensFromLocal(): ITokens | null {
  try {
    const authTokens = JSON.parse(localStorage.getItem('auth_tokens')!);
    return authTokens;
  } catch (error) {
    return null;
  }
}

export function removeTokensFromLocal(): void {
  localStorage.removeItem('auth_tokens');
}

export function setSelectedTopicToSession(topic: ITopic) {
  sessionStorage.setItem('topic', JSON.stringify(topic));
}

export function getSelectedTopicFromSession(): ITopic | null {
  try {
    const topic = JSON.parse(sessionStorage.getItem('topic')!);
    return topic;
  } catch (error) {
    return null;
  }
}
