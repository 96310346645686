import { Api } from 'api';

export const useUpdloadForm = () => {
  const uploadFile = async (file: File): Promise<string> => {
    const { postURL, formData, mStorageId } = await Api.mStorage.createSession({
      objectName: file.name,
      mimeType: file.type,
      size: file.size,
    });

    const form = new FormData();

    for (const [name, value] of Object.entries(formData)) {
      form.append(name, value as string);
    }

    form.append('file', file);
    await Api.mStorage.uploadFile(postURL, form);
    return mStorageId;
  };

  return {
    uploadFile,
  };
};
