import { BaseApi } from 'api/base-api';
import { IPaginativeResponse } from 'api/base-api.interface';
import urlJoin from 'url-join';
import {
  IQueryStatistic,
  IQueryStatisticUser,
  IStatisticApi,
  IStatisticDownloadHistory,
  IStatisticResource,
  IStatisticTopic,
  IStatisticUser,
  IStatisticView,
} from './interfaces';

export class StatisticApi extends BaseApi implements IStatisticApi {
  constructor() {
    super('statistic');
  }

  async getStatisticTopic(
    query?: IQueryStatistic | undefined,
  ): Promise<IStatisticTopic> {
    const response = await this.axiosClient.get<IStatisticTopic>(
      urlJoin(this.version, this.path, 'topic'),
      {
        params: query,
      },
    );
    return response.data;
  }

  async getStatisticResource(
    query?: IQueryStatistic | undefined,
  ): Promise<IStatisticResource> {
    const response = await this.axiosClient.get<IStatisticResource>(
      urlJoin(this.version, this.path, 'resource'),
      {
        params: query,
      },
    );
    return response.data;
  }

  async getStatisticDownloadHistory(
    query?: IQueryStatistic | undefined,
  ): Promise<IStatisticDownloadHistory> {
    const response = await this.axiosClient.get<IStatisticDownloadHistory>(
      urlJoin(this.version, this.path, 'download-history'),
      {
        params: query,
      },
    );
    return response.data;
  }

  async getStatisticView(
    query?: IQueryStatistic | undefined,
  ): Promise<IStatisticView> {
    const response = await this.axiosClient.get<IStatisticView>(
      urlJoin(this.version, this.path, 'view-collector'),
      {
        params: query,
      },
    );
    return response.data;
  }

  async getStatisticUser(
    query?: IQueryStatistic | undefined,
    queryUser?: IQueryStatisticUser | undefined,
  ): Promise<IPaginativeResponse & { items: IStatisticUser[] }> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path, 'user'),
      {
        params: { ...query, ...queryUser },
      },
    );
    return response.data;
  }
}
