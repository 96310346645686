export interface IPaginativeResponse {
  total: number;
}

export interface IPaginativeQuery {
  page: number;
  pageSize: number;
  search?: string;
  sortOrder?: ESortOrder;
}

export enum ESortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
