import { IPaginativeResponse } from 'api/base-api.interface';
import urlJoin from 'url-join';
import { BaseApi } from '../base-api';
import {
  IDataResource,
  IPaginativeQueryResource,
  IQueryGetResource,
  IResourceApi,
  IResourceFileUrlResponse,
  IResourceResponse,
} from './interfaces';

export class ResourceApi extends BaseApi implements IResourceApi {
  constructor() {
    super('resources');
  }

  async createResource(data: IDataResource): Promise<IResourceResponse> {
    const response = await this.axiosClient.post<IResourceResponse>(
      urlJoin(this.version, this.path),
      data,
    );
    return response.data;
  }

  async updateResource(id: string, data: IDataResource): Promise<void> {
    await this.axiosClient.patch<void>(
      urlJoin(this.version, this.path, id),
      data,
    );
  }

  async getResource(query: IQueryGetResource): Promise<IResourceResponse> {
    const response = await this.axiosClient.get<IResourceResponse>(
      urlJoin(this.version, this.path, query.id),
    );
    return response.data;
  }

  async getPreviewUrl(
    query: IQueryGetResource,
  ): Promise<IResourceFileUrlResponse> {
    const response = await this.axiosClient.get<IResourceFileUrlResponse>(
      urlJoin(this.version, this.path, query.id, 'preview-url'),
    );
    return response.data;
  }

  async getDownloadUrl(
    query: IQueryGetResource,
  ): Promise<IResourceFileUrlResponse> {
    const response = await this.axiosClient.get<IResourceFileUrlResponse>(
      urlJoin(this.version, this.path, query.id, 'download-url'),
    );
    return response.data;
  }

  async getListResource(
    query?: IPaginativeQueryResource | undefined,
  ): Promise<IPaginativeResponse & { items: IResourceResponse[] }> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path),
      {
        params: query,
      },
    );
    return response.data;
  }

  async deleteResource(id: string): Promise<void> {
    await this.axiosClient.delete<void>(urlJoin(this.version, this.path, id));
  }
}
