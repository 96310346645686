import { AxiosInstance } from 'axios';
import axiosClient from './axios';

type ApiVersion = 'v1';

export class BaseApi {
  protected axiosClient: AxiosInstance;

  constructor(protected path: string, protected version: ApiVersion = 'v1') {
    this.path = path;
    this.version = version;
    this.axiosClient = axiosClient;
  }
}
