import { IUserResponse } from 'api/user';
import { IPaginativeQuery, IPaginativeResponse } from '../base-api.interface';

export interface IStatisticApi {
  getStatisticTopic(query?: IQueryStatistic): Promise<IStatisticTopic>;
  getStatisticResource(query?: IQueryStatistic): Promise<IStatisticResource>;
  getStatisticDownloadHistory(
    query?: IQueryStatistic,
  ): Promise<IStatisticDownloadHistory>;
  getStatisticView(query?: IQueryStatistic): Promise<IStatisticView>;
  getStatisticUser(
    query?: IQueryStatistic,
    queryUser?: IQueryStatisticUser,
  ): Promise<IPaginativeResponse & { items: IStatisticUser[] }>;
}

export interface IStatisticTopic {
  totalTopic: number;
}

export interface IStatisticResource {
  totalResource: number;
  totalAudio: number;
  totalVideo: number;
}

export interface IStatisticDownloadHistory {
  total: number;
  result: IPeriodData[];
}

export interface IStatisticView extends IStatisticDownloadHistory {}

export interface IStatisticUser extends IUserResponse {
  totalDownloads: string;
  totalViews: string;
}

export interface IQueryStatistic {
  from?: string;
  to?: string;
}

export interface IQueryStatisticUser extends IPaginativeQuery {
  sortBy?: EStatisticUserSort;
}

export interface IPeriodData {
  period: string;
  total: number;
}

export enum EStatisticUserSort {
  TotalDownload = 'totalDownloads',
  TotalView = 'totalViews',
}
