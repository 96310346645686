import { isAxiosError } from 'axios';
import { Action } from 'redux-saga';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { authActions } from '.';
import { Api } from '../../../../api';
import { IAuthTokens } from '../../../../api/auth/interfaces';
import {
  debug,
  getTokensFromLocal,
  parseError,
  removeTokensFromLocal,
  setTokensToLocal,
} from '../../../../utils/functions';
import { selectAuth } from './selectors';
import { AuthActionTypes, AuthState, ITokens } from './types';

function* getAuthTokensByCode(action: Action<string> & { code: string }) {
  try {
    debug(action.code);
    const authTokens: IAuthTokens = yield call(
      Api.auth.verify.bind(Api.auth),
      action.code,
    );
    const tokens: ITokens = {
      ...authTokens,
      expires_at: Date.now() + 1000 * authTokens.expires_in,
      refresh_expires_at: Date.now() + 1000 * authTokens.refresh_expires_in,
    };
    setTokensToLocal(tokens);
    yield put(authActions.setTokens(tokens));
    yield put(authActions.setIsLogged(true));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      switch (error.response?.status) {
        case 400:
          yield put(
            authActions.setError(
              'Người dùng chưa cập nhật đầy đủ thông tin cá nhân trên Keycloak',
            ),
          );
          break;
        case 403:
          yield put(
            authActions.setError(
              'Người dùng không có quyền truy cập trang web',
            ),
          );
          break;
        case 404:
          yield put(authActions.setError('Người dùng không tồn tại'));
          break;
        default:
          yield put(authActions.setError(parseError(error).message));
      }
    }
  }
}

function* loadLocalAuthTokens() {
  const tokens: ITokens | null = getTokensFromLocal();
  if (!tokens) {
    yield put(authActions.setIsLogged(false));
  } else {
    yield put(authActions.setIsLogged(true));
    yield put(authActions.setTokens(tokens));
  }
}

function* refreshTokens() {
  try {
    const { tokens: oldTokens }: AuthState = yield select(selectAuth);
    const newTokens: IAuthTokens = yield call(
      Api.auth.refreshToken.bind(Api.auth, oldTokens.refresh_token),
    );
    const tokens: ITokens = {
      ...newTokens,
      expires_at: Date.now() + 1000 * newTokens.expires_in,
      refresh_expires_at: Date.now() + 1000 * newTokens.refresh_expires_in,
    };
    setTokensToLocal(tokens);
    yield put(authActions.setIsLogged(true));
    yield put(authActions.setTokens(tokens));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      switch (error.response?.status) {
        case 403:
          yield put(
            authActions.setError('Người dùng chưa có quyền truy cập trang web'),
          );
          break;
        case 404:
          yield put(authActions.setError('Người dùng không tồn tại'));
          break;
      }
    }
    yield call(() => removeLocalAuthTokens());
  }
}

function* removeLocalAuthTokens() {
  removeTokensFromLocal();
  yield put(authActions.setIsLogged(false));
}

function* logout() {
  const tokens = getTokensFromLocal();
  yield call(Api.auth.logout.bind(Api.auth, tokens?.refresh_token as string));
  yield call(() => removeLocalAuthTokens());
}

export function* authSaga() {
  yield takeLatest(AuthActionTypes.GetAuthTokensByCode, getAuthTokensByCode);
  yield takeLatest(AuthActionTypes.LoadLocalAuthTokens, loadLocalAuthTokens);
  yield takeLeading(AuthActionTypes.RefreshTokens, refreshTokens);
  yield takeLatest(
    AuthActionTypes.RemoveLocalAuthTokens,
    removeLocalAuthTokens,
  );
  yield takeLatest(AuthActionTypes.Logout, logout);
}
