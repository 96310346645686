import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarIcon, ReloadIcon } from '@radix-ui/react-icons';
import { Api } from 'api';
import { Skeleton } from 'app/components/ui/skeleton';
import { isAxiosError } from 'axios';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import {
  EResourceType,
  IDataResource,
  IResourceResponse,
} from '../../../../api/resource';
import { useUpdloadForm } from '../../../../customhooks/uploadFile';
import { debug, parseError } from '../../../../utils/functions';
import { cn } from '../../../../utils/shadcn';
import { useToast } from '../../..//components/ui/use-toast';
import { Caption } from '../../../components/Caption/Caption';
import { Button } from '../../../components/ui/button';
import { Calendar } from '../../../components/ui/calendar';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import { Textarea } from '../../../components/ui/textarea';
import Player from './Player';
import { SelectTopic } from './SelectTopic';
import { useAdminResourceSlice } from './slice';
import { AdminResourceActionTypes } from './slice/types';

interface EditResourceDialogProps {
  resource: IResourceResponse;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditResourceFormSchema = z.object({
  thumbnail: z.union([z.any().refine(file => !!file), z.string().url()]),
  file: z.union([z.any().refine(file => !!file), z.string().url()]),
  name: z
    .string({ required_error: 'Không được để trống' })
    .max(255, 'Độ dài không vượt quá 255 kí tự.'),
  description: z
    .string()
    .max(1000, 'Độ dài không vượt quá 1000 kí tự.')
    .optional(),
  type: z.nativeEnum(EResourceType, { required_error: 'Phải chọn 1 loại' }),
  author: z.string().max(255, 'Độ dài không vượt quá 255 kí tự.').optional(),
  year: z.coerce.date().optional(),
  topics: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
    {
      required_error: 'Chọn ít nhất 1 chủ đề',
    },
  ),
});
export function EditResouceDialog(props: EditResourceDialogProps) {
  const { resource, open, setOpen } = props;

  const [isSubmiting, setIsSubmiting] = React.useState<boolean>(false);
  const [loadingThumbnail, setLoadingThumbnail] = React.useState<boolean>(true);
  //Hook
  const { uploadFile } = useUpdloadForm();
  const { toast } = useToast();
  // Redux
  const dispatch = useDispatch();
  const { actions } = useAdminResourceSlice();

  //Form
  const form = useForm<z.infer<typeof EditResourceFormSchema>>({
    resolver: zodResolver(EditResourceFormSchema),
    defaultValues: {
      name: resource.name,
      description: resource.description,
      type: resource.type,
      author: resource.author ?? '',
      ...(!!resource.year && { year: new Date(resource.year) }),
      topics: resource.topics,
      thumbnail: resource.thumbnail.url,
    },
  });
  const { watch } = form;
  const thumbnailWatcher = watch('thumbnail');
  const fileWatcher = watch('file');
  // Function
  const handleThumbnailLoad = () => {
    setLoadingThumbnail(false);
  };
  const handleChange = () => {
    setOpen(!open);
    form.reset();
  };
  const handleSubmit = async (
    values: z.infer<typeof EditResourceFormSchema>,
  ) => {
    try {
      setIsSubmiting(true);
      const data: IDataResource = {
        name: values.name,
        description: values.description ?? '',
        type: values.type,
        author: values.author,
        year: values.year,
        topics: values.topics.map(topic => topic.id),
      };
      if (thumbnailWatcher instanceof File) {
        const thumbnailMStorageId = await uploadFile(values.thumbnail);
        data.thumbnail = thumbnailMStorageId;
      }
      if (fileWatcher instanceof File) {
        const fileMStorageId = await uploadFile(values.file);
        data.file = fileMStorageId;
      }

      await Api.resource.updateResource(resource.id, data);
      dispatch({ type: AdminResourceActionTypes.GetListResouce });
      toast({
        variant: 'success',
        title: 'Chỉnh sửa nội dung thành công',
      });
      handleChange();
    } catch (error) {
      debug(error);
      if (isAxiosError(error)) {
        dispatch(actions.setError(parseError(error).message));
        toast({
          variant: 'destructive',
          title: parseError(error).message,
        });
      }
    } finally {
      setIsSubmiting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      const { fileUrl } = await Api.resource.getPreviewUrl({
        id: resource.id,
      });
      form.reset({
        name: resource.name,
        description: resource.description,
        thumbnail: resource.thumbnail.url,
        type: resource.type,
        author: resource.author ?? '',
        ...(!!resource.year && { year: new Date(resource.year) }),
        topics: resource.topics,
        file: fileUrl,
      });
    })();
  }, []);
  return (
    <Dialog open={open} onOpenChange={handleChange}>
      <DialogContent className="max-w-6xl overflow-y-scroll max-h-[95vh]">
        <DialogHeader>
          <DialogTitle>Sửa nội dung {resource.name}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <div className="grid grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Loại</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Chọn loại tài nguyên bạn đăng tải" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.values(EResourceType).map(key => (
                        <SelectItem key={key} value={key}>
                          {key}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="thumbnail"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Ảnh đại diện</FormLabel>
                    <FormControl>
                      <Input
                        {...fieldProps}
                        type="file"
                        accept="image/*"
                        onChange={e => onChange(e.currentTarget.files?.[0])}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="aspect-video w-full rounded-md  border">
                {loadingThumbnail && <Skeleton className="w-full h-full" />}
                <img
                  src={
                    typeof thumbnailWatcher === 'string'
                      ? thumbnailWatcher
                      : thumbnailWatcher instanceof File
                      ? URL.createObjectURL(thumbnailWatcher)
                      : require('app/assets/resource.png')
                  }
                  alt="thumbnail-preview"
                  onLoad={handleThumbnailLoad}
                  className={cn(
                    'w-full h-full bg-border object-cover',
                    loadingThumbnail && 'hidden',
                  )}
                />
              </div>
            </div>
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="file"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Tài nguyên</FormLabel>
                    <FormControl>
                      <Input
                        {...fieldProps}
                        type="file"
                        accept="video/*, audio/*"
                        onChange={e => onChange(e.currentTarget.files?.[0])}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div>
                {fileWatcher ? (
                  <Player fileWatcher={fileWatcher} type={resource.type} />
                ) : (
                  <div className="aspect-video w-full rounded-md border relative">
                    <img
                      src={
                        resource.thumbnail.url
                          ? resource.thumbnail.url
                          : require('app/assets/resource.png')
                      }
                      alt="file-preview"
                      className="w-full h-full bg-border object-cover"
                    />
                    <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
                      <AiOutlineLoading3Quarters className="font-normal w-[72px] h-[72px] animate-spin" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tiêu đề</FormLabel>
                  <FormControl>
                    <Input placeholder="Tiêu đề" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="author"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tên tác giả</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Tên tác giả"
                      {...field}
                      autoComplete={'true'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mô tả</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Thông tin thêm về nội dung."
                      rows={5}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-rows-2">
              <FormField
                control={form.control}
                name="year"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ngày sản xuất</FormLabel>
                    <FormControl>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-full justify-start text-left font-normal flex',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {field.value ? (
                              moment(field.value).format('DD/MM/YYYY')
                            ) : (
                              <span>Chọn 1 ngày</span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 " align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                            components={{
                              Caption: Caption,
                            }}
                          />
                        </PopoverContent>
                      </Popover>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="topics"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Chủ đề</FormLabel>
                  <FormControl>
                    <SelectTopic
                      values={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </Form>
        <DialogFooter>
          <Button
            disabled={isSubmiting}
            onClick={form.handleSubmit(handleSubmit)}
            className="min-w-32"
          >
            {isSubmiting ? (
              <ReloadIcon className="mr-2 h-5 w-5 animate-spin" />
            ) : (
              'Sửa'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
