import axios, { AxiosInstance } from 'axios';
import urlJoin from 'url-join';
import { AppRoutes } from '../../app/constants';
import { BaseApi } from '../base-api';
import { IAuthApi, IAuthTokens } from './interfaces';

export class AuthApi extends BaseApi implements IAuthApi {
  protected axiosClientNoTokens: AxiosInstance;
  constructor() {
    super('auth');
    this.axiosClientNoTokens = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
  }

  async getSsoUrl(
    redirectUri = urlJoin(
      process.env.REACT_APP_WEB_URL as string,
      AppRoutes.Auth,
    ),
  ): Promise<string> {
    const response = await this.axiosClientNoTokens.get<{ url: string }>(
      urlJoin(this.version, this.path, 'sso-url'),
      { params: { redirectUri } },
    );
    return response.data.url;
  }

  async getChangePasswordUrl(): Promise<string> {
    const response = await this.axiosClientNoTokens.get<{ url: string }>(
      urlJoin(this.version, this.path, 'change-password'),
    );
    return response.data.url;
  }

  async refreshToken(
    refreshToken: string,
    redirectUri = urlJoin(
      process.env.REACT_APP_WEB_URL as string,
      AppRoutes.Auth,
    ),
  ): Promise<IAuthTokens> {
    const response = await this.axiosClientNoTokens.post<IAuthTokens>(
      urlJoin(this.version, this.path, 'refresh-token'),
      { refreshToken, redirectUri },
    );
    return response.data;
  }

  async verify(
    code: string,
    redirectUri = urlJoin(
      process.env.REACT_APP_WEB_URL as string,
      AppRoutes.Auth,
    ),
  ): Promise<IAuthTokens> {
    const response = await this.axiosClientNoTokens.post<IAuthTokens>(
      urlJoin(this.version, this.path, 'verify'),
      { code, redirectUri },
    );
    return response.data;
  }

  async logout(refreshToken: string): Promise<void> {
    await this.axiosClientNoTokens.post(
      urlJoin(this.version, this.path, 'logout'),
      {
        refreshToken,
      },
    );
  }
}
