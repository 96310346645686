import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppPageMetaDataItem } from '../../constants';

export interface PageHeaderProps {
  metadata: AppPageMetaDataItem;
}
export function PageHeader(props: PageHeaderProps) {
  return (
    <>
      <Helmet title={props.metadata.title}></Helmet>
      <div className="space-y-2">
        <h1 className="text-2xl font-bold">{props.metadata.title}</h1>
        {props.metadata.description && (
          <p className="max-w-[700px]">{props.metadata.description}</p>
        )}
      </div>
    </>
  );
}
