import { zodResolver } from '@hookform/resolvers/zod';
import { MixerHorizontalIcon } from '@radix-ui/react-icons';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { cn } from '../../../utils/shadcn';
import { FilterValue, SortOrder } from '../../constants';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Form, FormControl, FormField, FormItem, FormLabel } from '../ui/form';
import { Input } from '../ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

export const FilterSchema = z.object({
  type: z.array(z.string()).optional(),
  search: z.string().optional(),
  sortBy: z.array(z.string()).optional(),
  sortOrder: z.array(z.string()).optional(),
});
interface FilterProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: FilterValue[];
  sortBy: FilterValue[];
  handleSubmit: (values: z.infer<typeof FilterSchema>) => void;
}
export const Filter = memo((props: FilterProps) => {
  const { handleSubmit, sortBy, type, className } = props;
  const form = useForm<z.infer<typeof FilterSchema>>({
    resolver: zodResolver(FilterSchema),
    defaultValues: {
      sortBy: [],
      sortOrder: [],
    },
  });
  return (
    <div className="flex gap-1">
      <Form {...form}>
        <FormField
          control={form.control}
          name="search"
          render={({ field }) => (
            <FormItem className="max-w-60">
              <FormControl>
                <Input placeholder="Tìm kiếm" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button variant={'outline'}>
              <MixerHorizontalIcon
                width={'18px'}
                height={'18px'}
                className="mr-2"
              />
              Lọc
            </Button>
          </PopoverTrigger>
          <PopoverContent className={cn('min-w-80', className)}>
            <div className="flex space-x-9">
              {type && (
                <FormField
                  control={form.control}
                  name="type"
                  render={() => (
                    <FormItem>
                      <div className="mb-4">
                        <FormLabel className="text-base">Loại</FormLabel>
                      </div>
                      {type.map(item => (
                        <FormField
                          key={item.id}
                          control={form.control}
                          name="type"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={item.id}
                                className="flex flex-row items-start space-x-3 space-y-0"
                              >
                                <FormControl>
                                  <Checkbox
                                    checked={field.value?.includes(item.id)}
                                    onCheckedChange={checked => {
                                      return checked
                                        ? field.onChange([item.id])
                                        : field.onChange(
                                            field.value?.filter(
                                              value => value !== item.id,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="text-sm font-normal">
                                  {item.name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="sortBy"
                render={() => (
                  <FormItem>
                    <div className="mb-4">
                      <FormLabel className="text-base">Sắp xếp theo</FormLabel>
                    </div>
                    {sortBy.map(item => (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="sortBy"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item.id)}
                                  onCheckedChange={checked => {
                                    return checked
                                      ? field.onChange([item.id])
                                      : field.onChange(
                                          field.value?.filter(
                                            value => value !== item.id,
                                          ),
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-sm font-normal">
                                {item.name}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sortOrder"
                render={() => (
                  <FormItem>
                    <div className="mb-4">
                      <FormLabel className="text-base">
                        Thứ tự sắp xếp
                      </FormLabel>
                    </div>
                    {SortOrder.map(item => (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="sortOrder"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item.id)}
                                  onCheckedChange={checked => {
                                    return checked
                                      ? field.onChange([item.id])
                                      : field.onChange(
                                          field.value?.filter(
                                            value => value !== item.id,
                                          ),
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-sm font-normal">
                                {item.name}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </FormItem>
                )}
              />
            </div>
          </PopoverContent>
        </Popover>
      </Form>
      <Button onClick={form.handleSubmit(handleSubmit)}>Tìm kiếm</Button>
    </div>
  );
});
