import { IPaginativeResponse } from 'api/base-api.interface';
import urlJoin from 'url-join';
import { BaseApi } from '../base-api';
import {
  IActiveUser,
  IAssignTopicForUser,
  IGetRoleQuery,
  IPaginativeQueryUser,
  IRoleResponse,
  IUserApi,
  IUserData,
  IUserResponse,
  IUserWithRole,
} from './interfaces';
export class UserApi extends BaseApi implements IUserApi {
  constructor() {
    super('users');
  }

  async createUser(data: IUserData): Promise<IUserResponse> {
    const response = await this.axiosClient.post<IUserResponse>(
      urlJoin(this.version, this.path),
      data,
    );
    return response.data;
  }

  async getListUser(
    query?: IPaginativeQueryUser | undefined,
  ): Promise<IPaginativeResponse & { items: IUserResponse[] }> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path),
      {
        params: query,
      },
    );
    return response.data;
  }
  async getProfile(): Promise<IUserWithRole> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path, 'your-profile'),
    );
    return response.data;
  }

  async getRoles(query: IGetRoleQuery): Promise<IRoleResponse> {
    const response = await this.axiosClient.get(
      urlJoin(this.version, this.path, '/roles'),
      {
        params: query,
      },
    );
    return response.data;
  }

  async activeUser(data: IActiveUser): Promise<void> {
    await this.axiosClient.patch<void>(
      urlJoin(this.version, this.path, 'active'),
      data,
    );
  }

  async assignTopicForUser(data: IAssignTopicForUser): Promise<void> {
    await this.axiosClient.patch<void>(
      urlJoin(this.version, this.path, 'assign-topic'),
      data,
    );
  }

  async updateUser(id: string, data: IUserData): Promise<void> {
    await this.axiosClient.patch<void>(
      urlJoin(this.version, this.path, id),
      data,
    );
  }

  async deleteUser(id: string): Promise<void> {
    await this.axiosClient.delete<void>(urlJoin(this.version, this.path, id));
  }
}
