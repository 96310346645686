/**
 *
 * Asynchronously loads the component for BaseWebPage
 *
 */
import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { PageLoader } from '../PageLoader';

export const BaseWebPage = lazyLoad(
  () => import('./index'),
  module => module.BaseWebPage,
  {
    fallback: <PageLoader />,
  },
);
