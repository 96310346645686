/**
 *
 * Asynchronously loads the component for AdminDashboard
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AdminDashboard = lazyLoad(
  () => import('./index'),
  module => module.AdminDashboard,
);
