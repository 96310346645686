import { IPaginativeQuery, IPaginativeResponse } from '../base-api.interface';
import { IThumbnail } from '../mstorage';
import { INameTopic } from '../topic';

export interface IResourceApi {
  createResource(data: IDataResource): Promise<IResourceResponse>;

  updateResource(id: string, data: IDataResource): Promise<void>;

  getResource(query: IQueryGetResource): Promise<IResourceResponse>;

  getPreviewUrl(query: IQueryGetResource): Promise<IResourceFileUrlResponse>;

  getDownloadUrl(query: IQueryGetResource): Promise<IResourceFileUrlResponse>;

  getListResource(
    query?: IPaginativeQueryResource,
  ): Promise<IPaginativeResponse & { items: IResourceResponse[] }>;

  deleteResource(id: string): Promise<void>;
}

export interface IPaginativeQueryResource extends IPaginativeQuery {
  sortBy?: EResourceKeySort;
  type?: EResourceType;
  topics?: string[];
}

export interface IResourceResponse {
  id: string;
  name: string;
  description: string;
  type: EResourceType;
  author: string;
  year: string;
  topics: INameTopic[];
  createdAt: string;
  downloadHistory: IDownloadHistory[];
  thumbnail: IThumbnail;
}

export interface IDataResource {
  name: string;
  description: string;
  type: EResourceType;
  author?: string;
  year?: Date;
  thumbnail?: string;
  file?: string;
  topics: string[];
}

export interface IResourceFileUrlResponse {
  fileUrl: string;
}

export interface IQueryGetResource {
  id: string;
}

export interface IDownloadHistory {
  id: string;
}

export enum EResourceKeySort {
  Name = 'name',
  Author = 'author',
  Year = 'year',
  CreatedAt = 'createdAt',
}

export enum EResourceType {
  AUDIO = 'audio',
  VIDEO = 'video',
}
