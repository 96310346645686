import React from 'react';
import ReactPlayer from 'react-player';
import { EResourceType } from '../../../../api/resource';
import { cn } from '../../../../utils/shadcn';

interface PlayerProps {
  fileWatcher: File;
  type?: EResourceType;
}
const Player: React.FC<PlayerProps> = (props: PlayerProps) => {
  const { fileWatcher, type } = props;

  const isAudio = React.useCallback(() => {
    return (
      (typeof fileWatcher === 'string' && type === EResourceType.AUDIO) ||
      (fileWatcher instanceof File && fileWatcher.type.includes('audio'))
    );
  }, [fileWatcher, type]);

  return (
    <ReactPlayer
      className={cn(
        'rounded-md object-cover',
        !isAudio() && 'aspect-video border',
      )}
      width={'100%'}
      height={isAudio() ? 50 : '100%'}
      controls
      url={
        typeof fileWatcher === 'string'
          ? fileWatcher
          : URL.createObjectURL(fileWatcher)
      }
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload noplaybackrate',
            disablePictureInPicture: true,
          },
        },
      }}
    />
  );
};
export default React.memo(Player);
