/**
 *
 * Asynchronously loads the component for BaseAdminPage
 *
 */
import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { PageLoader } from '../PageLoader';

export const BaseAdminPage = lazyLoad(
  () => import('./index'),
  module => module.BaseAdminPage,
  {
    fallback: <PageLoader />,
  },
);
