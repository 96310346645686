import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { formatDate } from 'date-fns/format';
import * as React from 'react';
import { CaptionLabelProps, useDayPicker } from 'react-day-picker';
import { Button } from '../ui/button';

export function CaptionLabel(
  props: CaptionLabelProps & {
    onPreviousYearClick?: VoidFunction;
    onPreviousMonthClick?: VoidFunction;
    onNextYearClick?: VoidFunction;
    onNextMonthClick?: VoidFunction;
  },
): JSX.Element {
  const { locale } = useDayPicker();
  const {
    onPreviousYearClick,
    onNextYearClick,
    onNextMonthClick,
    onPreviousMonthClick,
  } = props;

  return (
    <div key="caption" className="flex">
      <div className="flex justify-center items-center">
        <Button variant="ghost" className="p-2" onClick={onPreviousMonthClick}>
          <ChevronLeftIcon />
        </Button>
        <div className="flex justify-center min-w-[72px] text-sm">
          {formatDate(props.displayMonth, 'LLLL', { locale })}
        </div>
        <Button variant="ghost" className="p-2" onClick={onNextMonthClick}>
          <ChevronRightIcon />
        </Button>
      </div>
      <div className="flex justify-center items-center">
        <Button variant="ghost" className="p-2" onClick={onPreviousYearClick}>
          <ChevronLeftIcon />
        </Button>
        <div className="flex justify-center text-sm">
          {formatDate(props.displayMonth, 'y', { locale })}{' '}
        </div>
        <Button variant="ghost" className="p-2" onClick={onNextYearClick}>
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
}
