import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResourceResponse } from '../../../../api/resource';
import { INameTopic } from '../../../../api/topic';
import { ResourceThumbnailAdmin } from '../../../components/Video';
import { Button } from '../../../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip';
import { DropdownMenuTable } from './DropdownMenu';
import { ListTopic } from './ListTopic';
import { useAdminResourceSlice } from './slice';
import { selectAdminResource } from './slice/selectors';

export const ResourceTableColumn: ColumnDef<IResourceResponse>[] = [
  {
    accessorKey: 'id',
    header: () => <div className="text-center">STT</div>,
    cell: ({ row, table }) => (
      <div className="px-2 text-center">
        {(table
          .getSortedRowModel()
          ?.flatRows?.findIndex(flatRow => flatRow.id === row.id) || 0) +
          table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize +
          1}
      </div>
    ),
  },
  {
    accessorKey: 'thumbnail',
    header: () => <div className="text-center">Ảnh đại diện</div>,
    cell: ({ row }) => {
      const resource = row.original;
      return <ResourceThumbnailAdmin resource={resource} />;
    },
  },
  {
    accessorKey: 'name',
    header: 'Tên',
    cell: ({ row }) => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="max-w-60 truncate">{row.getValue('name')}</div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{row.getValue('name')}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
  },
  {
    accessorKey: 'type',
    header: 'Loại',
  },
  {
    accessorKey: 'author',
    header: 'Tác giả',
  },
  {
    accessorKey: 'year',
    header: () => <div className="text-left">Ngày sản xuất</div>,
    cell: ({ row }) => (
      <div className="text-right max-w-24">
        {row.getValue('year')
          ? moment(row.getValue('year')).format('DD/MM/YYYY')
          : ''}
      </div>
    ),
  },
  {
    accessorKey: 'topics',
    header: 'Chủ đề',
    cell: ({ row }) => {
      const topics: INameTopic[] = row.getValue('topics');
      return <ListTopic topics={topics} />;
    },
  },
  {
    id: 'actions',
    size: 100,
    cell: ({ row }) => {
      const resource = row.original;
      return <DropdownMenuTable resource={resource} />;
    },
  },
];

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  total: number;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  total,
}: DataTableProps<TData, TValue>) {
  const { filter } = useSelector(selectAdminResource);
  const dispatch = useDispatch();
  const { actions } = useAdminResourceSlice();
  const pagination = React.useCallback(() => {
    return {
      pageIndex: filter.page - 1,
      pageSize: filter.pageSize,
    };
  }, [filter]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: pagination(),
    },
    pageCount:
      total % filter.pageSize
        ? Math.floor(total / filter.pageSize) + 1
        : total / filter.pageSize,
    onPaginationChange: updater => {
      let nextState: PaginationState;
      if (typeof updater === 'function') {
        nextState = updater(pagination());
      } else {
        nextState = updater;
      }
      dispatch(
        actions.setFilter({
          page: nextState.pageIndex + 1,
          pageSize: nextState.pageSize,
        }),
      );
    },
  });
  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Không có kết quả.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {table.getRowModel().rows?.length > 0 && (
        <div className="flex items-center justify-end p-4">
          <div className="flex items-center space-x-6 lg:space-x-8">
            <div className="flex items-center space-x-2">
              <p className="text-sm font-medium">Số hàng trong trang</p>
              <Select
                value={`${table.getState().pagination.pageSize}`}
                onValueChange={value => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="h-8 w-[70px]">
                  <SelectValue
                    placeholder={table.getState().pagination.pageSize}
                  />
                </SelectTrigger>
                <SelectContent side="bottom">
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              Trang {table.getState().pagination.pageIndex + 1} trên{' '}
              {table.getPageCount()}
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <DoubleArrowLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <DoubleArrowRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
