import { AuthApi } from './auth';
import { MStorageApi } from './mstorage';
import { ResourceApi } from './resource';
import { StatisticApi } from './statistic';
import { TopicApi } from './topic';
import { UserApi } from './user';

export class Api {
  static auth = new AuthApi();
  static topic = new TopicApi();
  static mStorage = new MStorageApi();
  static resource = new ResourceApi();
  static user = new UserApi();
  static statistic = new StatisticApi();
}
