import * as React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { useAuthSlice } from '../../pages/AuthPage/slice';
import { selectAuth } from '../../pages/AuthPage/slice/selectors';

export function AuthGuard() {
  const navigate = useNavigate();
  const location = useLocation();

  useAuthSlice();
  const { isLogged } = useSelector(selectAuth);

  React.useEffect(() => {
    if (!isLogged)
      navigate(AppRoutes.Auth, {
        replace: true,
        state: { pathname: location.pathname },
      });
  }, [isLogged]);

  return <Outlet />;
}
