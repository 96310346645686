import { isAxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { profileActions } from '.';
import { Api } from '../../../../../api';
import { debug, parseError } from '../../../../../utils/functions';
import { ProfileActionTypes } from './types';

function* getProfile() {
  try {
    yield put(profileActions.setLoading(true));
    const user = yield call(Api.user.getProfile.bind(Api.user));
    yield put(profileActions.setUser(user));
  } catch (error) {
    debug(error);
    if (isAxiosError(error)) {
      yield put(profileActions.setError(parseError(error).message));
    }
  } finally {
    yield put(profileActions.setLoading(false));
  }
}

export function* profileSaga() {
  yield takeLatest(ProfileActionTypes.GetProfile, getProfile);
}
