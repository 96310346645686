/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { HomePage } from './pages/Web/HomePage/Loadable';

import 'styles/global.css';
import { AuthGuard } from './components/AuthGuard';
import { BaseAdminPage } from './components/BaseAdminPage/Loadable';
import { BaseWebPage } from './components/BaseWebPage/Loadable';
import { RoleGuard } from './components/RoleGuard';
import { AppConfig, AppRoutes } from './constants';
import { AdminDashboard } from './pages/Admin/AdminDashboard/Loadable';
import { AdminManageResources } from './pages/Admin/AdminManageResources';
import { AdminManageTopics } from './pages/Admin/AdminManageTopics/Loadable';
import { AdminManageUser } from './pages/Admin/AdminManageUser/Loadable';
import { AuthPage } from './pages/AuthPage';
import { LoginPage } from './pages/LoginPage';
import { AudioPage } from './pages/Web/AudioPage/Loadable';
import { ProfilePage } from './pages/Web/ProfilePage/Loadable';
import { ResourcesTopic } from './pages/Web/ResourcesTopic/Loadable';
import { TopicPage } from './pages/Web/TopicPage/Loadable';
import { VideoPage } from './pages/Web/VideoPage/Loadable';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={'%s - ' + AppConfig.Name}
        defaultTitle={AppConfig.Name}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={AppConfig.Description} />
      </Helmet>

      <Routes>
        <Route path={AppRoutes.Login} element={<LoginPage />} />
        <Route path={AppRoutes.Auth} element={<AuthPage />} />

        <Route element={<AuthGuard />}>
          <Route element={<BaseWebPage />}>
            <Route path={AppRoutes.Home} element={<HomePage />} />
            <Route path={AppRoutes.Topics} element={<TopicPage />} />
            <Route
              path={AppRoutes.ResourceTopic}
              element={<ResourcesTopic />}
            />
            <Route path={AppRoutes.Videos} element={<VideoPage />} />
            <Route path={AppRoutes.Audios} element={<AudioPage />} />
            <Route path={AppRoutes.Profile} element={<ProfilePage />} />
          </Route>

          <Route element={<RoleGuard />}>
            <Route element={<BaseAdminPage />}>
              <Route
                path={AppRoutes.AdminDashboard}
                element={<AdminDashboard />}
              />
              <Route
                path={AppRoutes.AdminManageTopics}
                element={<AdminManageTopics />}
              />
              <Route
                path={AppRoutes.AdminManageResources}
                element={<AdminManageResources />}
              />
              <Route
                path={AppRoutes.AdminManageUsers}
                element={<AdminManageUser />}
              />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
