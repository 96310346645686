import * as React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { useProfileSlice } from '../../pages/Web/ProfilePage/slice';
import { selectProfile } from '../../pages/Web/ProfilePage/slice/selectors';
import { PageLoader } from '../PageLoader';
import { useToast } from '../ui/use-toast';

export function RoleGuard() {
  const navigate = useNavigate();
  const { toast } = useToast();

  useProfileSlice();
  const { loading, user } = useSelector(selectProfile);
  React.useEffect(() => {
    if (user && !user.roles.some(role => role.name === 'admin')) {
      toast({
        variant: 'destructive',
        title: 'Người dùng không có quyền truy cập trang quản trị',
      });
      navigate(AppRoutes.Home);
    }
  }, [user]);

  return (
    <>
      {loading ? (
        <main className="container h-dvh flex items-center justify-center">
          <PageLoader className="size-24" />
        </main>
      ) : (
        <Outlet />
      )}
    </>
  );
}
