/**
 *
 * Asynchronously loads the component for ResourcesTopic
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ResourcesTopic = lazyLoad(
  () => import('./index'),
  module => module.ResourcesTopic,
);
