import { IPaginativeQuery, IPaginativeResponse } from '../base-api.interface';
import { INameTopic } from '../topic';

export interface IUserApi {
  createUser(data: IUserData): Promise<IUserResponse>;
  getListUser(
    query?: IPaginativeQueryUser,
  ): Promise<IPaginativeResponse & { items: IUserResponse[] }>;
  getProfile(): Promise<IUserWithRole>;
  getRoles(query: IGetRoleQuery): Promise<IRoleResponse>;
  activeUser(data: IActiveUser): Promise<void>;
  assignTopicForUser(data: IAssignTopicForUser): Promise<void>;
  updateUser(id: string, data: IUserData): Promise<void>;
  deleteUser(id: string): Promise<void>;
}

export interface IUserData {
  username?: string;
  password?: string;
  email: string;
  firstname: string;
  lastname: string;
  dob?: Date;
  description: string;
}

export interface IUserResponse {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  keycloakId: string;
  description: string;
  dob: Date;
  topics: INameTopic[];
}

export interface IUserWithRole {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  keycloakId: string;
  description: string;
  dob: Date;
  roles: IRole[];
}

export interface IGetRoleQuery {
  keycloakId: string;
}

export interface IActiveUser extends IGetRoleQuery {
  status: EAssignRoleStatus;
}

export interface IRoleResponse {
  roles: IRole[];
}

export interface IAssignTopicForUser {
  userId: string;
  topics: string[];
}

export interface IPaginativeQueryUser extends IPaginativeQuery {
  sortBy?: EUserKeySort;
}
export interface IRole {
  id: string;
  name: string;
}

export enum EUserKeySort {
  Username = 'username',
  Email = 'email',
  Firstname = 'firstname',
  Lastname = 'lastname',
  CreatedAt = 'createdAt',
}

export enum EAssignRoleStatus {
  Assign = 'assign',
  Unassign = 'unassign',
}
