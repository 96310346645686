import { ESortOrder } from '../../api/base-api.interface';
import { EResourceKeySort, EResourceType } from '../../api/resource';
import { ETopicKeySort } from '../../api/topic';
import { EUserKeySort } from '../../api/user';

export type FilterValue = {
  id: string;
  name: string;
};

export const TypeFilter: FilterValue[] = [
  {
    id: EResourceType.AUDIO,
    name: EResourceType.AUDIO,
  },
  {
    id: EResourceType.VIDEO,
    name: EResourceType.VIDEO,
  },
];

export const TopicSortBy: FilterValue[] = [
  {
    id: ETopicKeySort.Name,
    name: 'Tên',
  },
  {
    id: ETopicKeySort.CreatedAt,
    name: 'Ngày tạo',
  },
];

export const ResourceSortBy: FilterValue[] = [
  {
    id: EResourceKeySort.Name,
    name: 'Tên',
  },
  {
    id: EResourceKeySort.Author,
    name: 'Tác giả',
  },
  {
    id: EResourceKeySort.Year,
    name: 'Ngày sản xuất',
  },
  {
    id: EResourceKeySort.CreatedAt,
    name: 'Ngày tạo',
  },
];

export const UserSortBy: FilterValue[] = [
  {
    id: EUserKeySort.Username,
    name: 'Username',
  },
  {
    id: EUserKeySort.Email,
    name: 'Email',
  },
  {
    id: EUserKeySort.Firstname,
    name: 'Họ',
  },
  {
    id: EUserKeySort.Lastname,
    name: 'Tên',
  },
  {
    id: EUserKeySort.CreatedAt,
    name: 'Ngày tạo',
  },
];

export const SortOrder: FilterValue[] = [
  {
    id: ESortOrder.ASC,
    name: 'Tăng dần',
  },
  {
    id: ESortOrder.DESC,
    name: 'Giảm dần',
  },
];
