import { add } from 'date-fns/add';
import * as React from 'react';
import {
  CaptionProps,
  MonthChangeEventHandler,
  useDayPicker,
  useNavigation,
} from 'react-day-picker';
import { CaptionLabel } from './CaptionLabel';

export function Caption(props: CaptionProps): JSX.Element {
  const { displayMonth } = props;
  const context = useDayPicker();
  const { classNames, styles, onMonthChange } = context;

  const { goToMonth } = useNavigation();

  const handlePreviousClick = () => {
    handleMonthChange(add(displayMonth, { months: -1 }));
  };

  const handleNextClick = () => {
    handleMonthChange(add(displayMonth, { months: 1 }));
  };

  const handleMonthChange: MonthChangeEventHandler = newMonth => {
    goToMonth(newMonth);
    onMonthChange?.(newMonth);
  };

  const handleNextYearClick = () => {
    handleMonthChange(add(displayMonth, { years: 1 }));
  };
  const handlePreviousYearClick = () => {
    handleMonthChange(add(displayMonth, { years: -1 }));
  };

  return (
    <div className={classNames.caption} style={styles.caption}>
      <CaptionLabel
        displayMonth={displayMonth}
        onPreviousYearClick={handlePreviousYearClick}
        onNextYearClick={handleNextYearClick}
        onNextMonthClick={handleNextClick}
        onPreviousMonthClick={handlePreviousClick}
      />
    </div>
  );
}
