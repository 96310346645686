/**
 *
 * PageLoader
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { cn } from '../../../utils/shadcn';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function PageLoader(props: Props) {
  return <Div {...props} className={cn('size-12', props.className)}></Div>;
}

const Div = styled.div`
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;

  ::before,
  ::after {
    content: '';
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
  }
  ::before {
    border-color: #f03355 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  ::after {
    margin: 8px;
  }
  @keyframes l16 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
