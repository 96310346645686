import axios, { InternalAxiosRequestConfig } from 'axios';
import { store } from '../';
import { AuthActionTypes, ITokens } from '../app/pages/AuthPage/slice/types';
import { debug, wait } from '../utils/functions';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

type CustomRequestConfig = InternalAxiosRequestConfig & {
  retryIfError?: boolean | undefined;
};

const beforeRequest = async (config: CustomRequestConfig) => {
  const dispatch = store.dispatch;
  const state = store.getState;

  let tokens: ITokens = state().auth.tokens;
  if (tokens.refresh_expires_at <= Date.now()) {
    debug('RF rexpired');
    dispatch({ type: AuthActionTypes.RemoveLocalAuthTokens });
  }
  if (tokens.expires_at <= Date.now()) {
    config.retryIfError = true;
    dispatch({ type: AuthActionTypes.RefreshTokens });
  }

  // attach token to request
  config.headers.Authorization = `Bearer ${state().auth.tokens.access_token}`;
  return config;
};
const onError = async (error: { config: CustomRequestConfig }) => {
  if (error.config.retryIfError) {
    await wait(1);
    error.config.retryIfError = false;
    return axiosClient(error.config);
  }
  return Promise.reject(error);
};

axiosClient.interceptors.request.use(beforeRequest);
axiosClient.interceptors.response.use(undefined, onError);

export default axiosClient;
