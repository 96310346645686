import { DownloadIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import * as React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ReactPlayer from 'react-player';
import { debug, parseError } from 'utils/functions';
import { Api } from '../../../api';
import { IResourceResponse } from '../../../api/resource';
import { cn } from '../../../utils/shadcn';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader } from '../ui/dialog';
import { Separator } from '../ui/separator';
import { Skeleton } from '../ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { useToast } from '../ui/use-toast';

interface VideoListProps extends React.HTMLAttributes<HTMLDivElement> {}
export function VideoList({ className, ...props }: VideoListProps) {
  return (
    <div
      {...props}
      className={cn(
        'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 content-stretch',
        className,
      )}
    >
      {props.children}
    </div>
  );
}

export interface VideoItemProps extends React.HTMLAttributes<HTMLDivElement> {
  resources: IResourceResponse;
  setSelectedResource: (resource: IResourceResponse | null) => void;
}
export function VideoItem({ className, ...props }: VideoItemProps) {
  const { resources: resource, setSelectedResource } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={cn('space-y-2 cursor-pointer group', className)}
      onClick={() => setSelectedResource(resource)}
    >
      <div className="rounded-md overflow-hidden aspect-video w-full">
        {isLoading && <Skeleton className="'w-full h-full" />}
        <img
          src={
            resource.thumbnail.url
              ? resource.thumbnail.url
              : require('app/assets/resource.png')
          }
          alt="resource"
          onLoad={handleImageLoad}
          className={cn(
            'w-full h-full object-cover object-center bg-border border',
            isLoading && 'hidden',
          )}
        />
      </div>
      <div className="space-y-2">
        <div className="font-bold line-clamp-2 group-hover:underline">
          {resource.name}
        </div>
        <div className="flex space-x-2 mt-1">
          {resource.topics.slice(0, 3).map(item => (
            <TooltipProvider key={item.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    <Badge variant={'secondary'} className="max-w-20">
                      <div className="truncate">{item.name}</div>
                    </Badge>
                  </div>
                </TooltipTrigger>
                <TooltipContent align="center">
                  <p>{item.name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
          {resource.topics.length > 3 && (
            <Badge variant={'secondary'} className="min-w-10 justify-center">
              ...
            </Badge>
          )}
        </div>
        {resource.downloadHistory.length > 0 && (
          <Badge variant={'success'}>Đã tải xuống</Badge>
        )}
      </div>
    </div>
  );
}

export function VideoItemSkeleton({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('space-y-2', className)}>
      <div className="rounded-md overflow-hidden aspect-video w-full">
        <Skeleton className="w-full h-full" />
      </div>
      <div>
        <Skeleton className="w-full h-6" />
        <div className="flex space-x-2 mt-1">
          {new Array(3).fill(0).map((v, i) => (
            <Skeleton key={i} className="w-28 h-5" />
          ))}
        </div>
      </div>
    </div>
  );
}

interface DetailVideoProps {
  video: IResourceResponse;
  setSelectedResource: (resource: IResourceResponse | null) => void;
}
export function DetailVideo(props: DetailVideoProps) {
  const { video, setSelectedResource } = props;

  const [fileUrl, setFileUrl] = React.useState<string | null>();
  const [loadingDownload, setDownloadLoading] = React.useState<boolean>(false);

  const { toast } = useToast();

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const { fileUrl } = await Api.resource.getDownloadUrl({
        id: video.id,
      });
      const link = document.createElement('a');
      link.href = fileUrl;
      link.click();
    } catch (error) {
      debug(error);
      toast({
        variant: 'destructive',
        title: parseError(error).message,
      });
    } finally {
      setDownloadLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      const previewResponse = await Api.resource.getPreviewUrl({
        id: video.id,
      });
      setFileUrl(previewResponse.fileUrl);
    })();
  }, []);

  return (
    <Dialog open={!!video} onOpenChange={() => setSelectedResource(null)}>
      <DialogContent className="max-w-3xl overflow-y-auto max-h-[80vh] py-0">
        <DialogHeader></DialogHeader>
        <div className="space-y-4 py-6">
          {fileUrl ? (
            <div className="aspect-video bg-border rounded-md overflow-hidden relative">
              <ReactPlayer
                className="object-cover"
                controls
                url={fileUrl}
                playing={true}
                width={'100%'}
                height={'100%'}
                pip={true}
                stopOnUnmount={false}
                config={{
                  file: {
                    attributes: {
                      controlslist: 'nodownload noplaybackrate',
                      disablePictureInPicture: true,
                      preload: 'auto',
                      poster: video.thumbnail.url
                        ? video.thumbnail.url
                        : require('app/assets/resource.png'),
                    },
                  },
                }}
              />
            </div>
          ) : (
            <div className="aspect-video relative">
              <img
                src={
                  video.thumbnail.url
                    ? video.thumbnail.url
                    : require('app/assets/resource.png')
                }
                alt="file-preview"
                className="w-full h-full object-cover rounded-md"
              />
              <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
                <AiOutlineLoading3Quarters className="font-normal w-[72px] h-[72px] animate-spin" />
              </div>
            </div>
          )}
          <div className="space-y-3">
            <div className="flex justify-between space-x-2 sticky top-0 bg-white py-3">
              <div className="font-bold text-xl ">{video.name}</div>
              <Button onClick={handleDownload} size="icon">
                {loadingDownload ? (
                  <AiOutlineLoading3Quarters className="animate-spin" />
                ) : (
                  <DownloadIcon onClick={handleDownload} />
                )}
              </Button>
            </div>
            <div className="flex space-x-2 mt-1 flex-wrap">
              {video.topics.map(item => (
                <Badge key={item.id} variant={'secondary'}>
                  {item.name}
                </Badge>
              ))}
            </div>
            <Separator className="my-4" />
            <div className="text-justify text-sm space-y-2">
              <div className="flex space-x-4 h-5">
                <div>
                  <span className="font-medium">Ngày tạo: </span>{' '}
                  <span>{moment(video.createdAt).format('DD/MM/YYYY')}</span>
                </div>
                {video.author && (
                  <>
                    <Separator orientation="vertical" />
                    <div>
                      <span className="font-medium">Tác giả: </span>{' '}
                      <span>{video.author}</span>
                    </div>
                  </>
                )}
                {video.year && (
                  <>
                    <Separator orientation="vertical" />
                    <div>
                      <span className="font-medium">Ngày sản xuất: </span>{' '}
                      <span>{moment(video.year).format('DD/MM/YYYY')}</span>
                    </div>
                  </>
                )}
              </div>

              {video.description && (
                <div>
                  <p className="whitespace-pre text-wrap mt-4">
                    {video.description}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface ResourceThumbnailAdminProps {
  resource: IResourceResponse;
}
export function ResourceThumbnailAdmin(props: ResourceThumbnailAdminProps) {
  const { resource } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center">
      {isLoading && (
        <Skeleton className="w-14 xl:w-20 xl:h-[45px] rounded-md" />
      )}
      <img
        src={
          resource.thumbnail.url
            ? resource.thumbnail.url
            : require('app/assets/resource.png')
        }
        alt="resource"
        onLoad={handleImageLoad}
        className={cn(
          'w-14 aspect-square xl:w-20 xl:aspect-video object-cover object-center rounded-sm',
          !resource.thumbnail.url && 'scale-[3.0]',
          isLoading && 'hidden',
        )}
      />
    </div>
  );
}
